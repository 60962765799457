export class NfSaidaEnderecoCliente {
  id = null;
  complemento = null;
  codigoIbge = null;
  cep = null;
  logradouro = null;
  numero = null;
  bairro = null;
  cidade = null;
  estado = null;
  pessoaId = null;

  constructor(nfSaidaEnderecoCliente) {
    this.id = nfSaidaEnderecoCliente?.id;
    this.complemento = nfSaidaEnderecoCliente?.complemento;
    this.codigoIbge = nfSaidaEnderecoCliente?.codigoIbge;
    this.cep = nfSaidaEnderecoCliente?.cep;
    this.logradouro = nfSaidaEnderecoCliente?.logradouro;
    this.numero = nfSaidaEnderecoCliente?.numero;
    this.bairro = nfSaidaEnderecoCliente?.bairro;
    this.cidade = nfSaidaEnderecoCliente?.cidade;
    this.estado = nfSaidaEnderecoCliente?.estado;
    this.pessoaId = nfSaidaEnderecoCliente?.pessoaId;
  }
}
