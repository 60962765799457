import { CardHeader as CardHeaderMui } from "@mui/material";
import BotaoVoltar from "../botao-voltar/botao-voltar.component";
import Grid from "../grid/grid.component";
import UsuarioLog from "../usuario-log/usuario-log.component";

const CardHeader = ({
  action,
  avatar,
  classes,
  className,
  component,
  disableTypography,
  subheader,
  subheaderTypographyProps,
  sx,
  title,
  titleTypographyProps,
  botaoVoltar,
  onClickVoltar,
  userLog,
  ...other
}) => {
  return (
    <CardHeaderMui
      action={action}
      avatar={avatar}
      component={component}
      disableTypography={disableTypography ? true : false}
      subheader={subheader}
      subheaderTypographyProps={subheaderTypographyProps}
      classes={classes}
      className={className}
      sx={{
        ...sx,
        padding: "16px !important",
        margin: "0 !important",
      }}
      title={
        <Grid container alignItems="center">
          {botaoVoltar && <BotaoVoltar onClickVoltar={onClickVoltar} />}
          {title}
          {userLog && (
            <UsuarioLog
              createDate={userLog?.createdAt}
              editDate={userLog?.updatedAt}
              userCreatedLog={userLog?.userCreatedLog}
              userEditedLog={userLog?.userEditedLog}
            />
          )}
        </Grid>
      }
      titleTypographyProps={titleTypographyProps}
      {...other}
    ></CardHeaderMui>
  );
};

export default CardHeader;
