import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
  Autocomplete,
  CurrencyInput,
  DialogActions,
  CardHeader,
} from "../../../../components";
import { StorageHelper, InputErros, value } from "../../../../helpers";
import { formatPrice, responseErros, unformatPrice } from "../../../../utils";
import { CaixaService } from "../../../../services";

const OperacoesCaixaDialog = ({
  openDialogOperacoesCaixa,
  setOpenDialogOperacoesCaixa,
  bancoCaixa,
  setBancoCaixa,
  setListaProdutosVenda,
  dataAberturaCaixa,
  meioPagamentoList,
  validarToken,
  setLoading,
}) => {
  const [inputErro, setInputErro] = useState([]);
  const [valorSaldo, setValorSaldo] = useState(0);
  const [operacaoCaixa, setOperacaoCaixa] = useState({});
  const inputErros = new InputErros(inputErro, setInputErro);
  //service fora do componente
  const caixaService = new CaixaService();
  const storageHelper = new StorageHelper();
  const UNAUTHORIZED = 401;

  useEffect(() => {
    if (openDialogOperacoesCaixa && bancoCaixa) {
      buscarTotalSaldo(bancoCaixa.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialogOperacoesCaixa]);

  const abrirCaixaPadrao = async (aberto) => {
    setLoading(true);
    const body = {
      data: dataAberturaCaixa,
      bancoCaixaId: bancoCaixa.id,
      aberto,
    };
    const result = await caixaService.cadastrar(body);
    setLoading(false);
    setOpenDialogOperacoesCaixa(false);
    validarToken();
    if (result.isAxiosError) {
      responseErros(result);
    }
  };

  const buscarTotalSaldo = async (bancoCaixaId) => {
    const result = await caixaService.getTotaisDia(
      dataAberturaCaixa,
      bancoCaixaId
    );
    if (!result.isAxiosError) {
      setValorSaldo(result.data.saldo);
    } else {
      if (result.response.status === UNAUTHORIZED) {
        validarToken();
      } else {
        responseErros(result);
      }
    }
  };

  const handleInputChange = (e) => {
    e.persist();
    setOperacaoCaixa((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleValoresChange = (e) => {
    e.persist();
    let targetValue = parseFloat(unformatPrice(e.target.value));
    setOperacaoCaixa((prevState) => ({
      ...prevState,
      [e.target.name]: targetValue < 0 ? targetValue * -1 : targetValue,
    }));
  };

  const onChangeAutocomplete = (name, value) => {
    setOperacaoCaixa({
      ...operacaoCaixa,
      [name]: value ? value.id : undefined,
    });
  };

  const operacaoCaixaSubmit = async (event) => {
    event.preventDefault();
    const operacaoCaixaTemp = {
      ...operacaoCaixa,
      tipo:
        event.nativeEvent.submitter.id === "suprimentoButton"
          ? "ENTRADA"
          : "SAIDA",
    };
    setLoading(true);
    const result = await caixaService.movimentar(bancoCaixa.id, [
      operacaoCaixaTemp,
    ]);
    setLoading(false);
    if (!result.isAxiosError) {
      setOperacaoCaixa({});
      setOpenDialogOperacoesCaixa(false);
    } else {
      if (result.response.status === UNAUTHORIZED) {
        validarToken();
      } else {
        responseErros(result);
      }
    }
  };

  const handleFecharCaixa = () => {
    setBancoCaixa(null);
    setListaProdutosVenda([]);
    storageHelper.removeLocal("bancoCaixaIdPdv");
    abrirCaixaPadrao(false);
  };

  const handleCloseDialog = () => {
    setOpenDialogOperacoesCaixa(false);
  };

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={openDialogOperacoesCaixa}
      onClose={handleCloseDialog}
    >
      <form onSubmit={(event) => operacaoCaixaSubmit(event)}>
        <DialogContent>
          <CardHeader
            title="Operações Caixa"
            action={
              <IconButton onClick={handleCloseDialog}>
                <i
                  className="ph-fill ph-x-circle"
                  style={{ color: "#494C62", fontSize: 30 }}
                ></i>
              </IconButton>
            }
          />
          <Grid className="centralizar">
            <Grid>
              <TextField
                variant="outlined"
                label="Observação"
                id="observacao"
                name="observacao"
                margin="dense"
                type="text"
                fullWidth
                onChange={(event) => handleInputChange(event)}
                value={value.text(operacaoCaixa.observacao)}
                error={inputErros.get("observacao") ? true : false}
              />
              <CurrencyInput
                id="valor"
                name="valor"
                label="Valor"
                variant="outlined"
                margin="dense"
                required
                value={operacaoCaixa.valor ?? 0}
                onBlur={handleValoresChange}
                fullWidth
              />
              <Autocomplete
                required
                id="meioPagamentoId"
                name="meioPagamentoId"
                options={meioPagamentoList}
                filterSelectedOptions
                filterOptions={(options) =>
                  options.filter((item) => item.nfePagamento === "fpDinheiro")
                }
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option ? option.descricao : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meio de Pagamento"
                    variant="outlined"
                    margin="dense"
                    error={inputErros.get("meioPagamentoId")}
                    required
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete("meioPagamentoId", newValue);
                }}
                value={value.autoComplete(
                  meioPagamentoList,
                  operacaoCaixa?.meioPagamentoId
                )}
              />
            </Grid>
          </Grid>
          <h6 className="centralizar mt-3">Saldo em Caixa</h6>
          <h6 id="valor-saldo-operacao">
            {formatPrice(valorSaldo) || "R$ 0,00"}
          </h6>
        </DialogContent>
        <Grid container spacing={2} sx={{ px: 3 }}>
          <Grid item xs={6}>
            <Button
              id="suprimentoButton"
              name="suprimentoButton"
              className="mb-2"
              color="success"
              size="large"
              type="submit"
              variant="contained"
              disableElevation
              fullWidth
            >
              <i
                className="ph-fill ph-plus-circle"
                style={{ color: "white", fontSize: 22, marginRight: 10 }}
              ></i>
              Suprimento
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              id="sangriaButton"
              name="sangriaButton"
              color="error"
              size="large"
              variant="contained"
              type="submit"
              disableElevation
              fullWidth
            >
              Sangria
              <i
                className="ph-fill ph-minus-circle"
                style={{ color: "white", fontSize: 22, marginLeft: 10 }}
              ></i>
            </Button>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            className="mx-2 mb-3"
            color="info"
            size="large"
            variant="contained"
            onClick={handleFecharCaixa}
            disableElevation
            fullWidth
          >
            <i
              className="ph-fill ph-shopping-bag"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar Caixa
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OperacoesCaixaDialog;
