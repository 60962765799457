const valueHelper = {
  date: (date) => {
    return date ? date : null;
  },
  text: (value) => {
    return value ? value : "";
  },
  autoComplete: (lista, valor) => {
    if (Array.isArray(valor)) {
      return valor.map((id) => {
        const objeto = lista.find((objeto) => objeto?.id === id);
        return objeto ? objeto : "";
      });
    } else {
      const objeto = lista.find((objeto) => objeto?.id === valor);
      return objeto ? objeto : "";
    }
  },
};

export default valueHelper;
