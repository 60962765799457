export class OperacaoFiscal {
  modeloDocumento = null;
  movimentacaoFinanceira = null;
  naturezaOperacao = null;
  movimentacaoEstoque = null;
  tipoOperacao = null;
  finalidade = null;
  naoGerarComissao = null;

  constructor(operacaoFiscal) {
    this.modeloDocumento = operacaoFiscal.modeloDocumento;
    this.movimentacaoFinanceira = operacaoFiscal.movimentacaoFinanceira;
    this.movimentacaoEstoque = operacaoFiscal.movimentacaoEstoque;
    this.tipoOperacao = operacaoFiscal.tipoOperacao;
    this.finalidade = operacaoFiscal.finalidade;
    this.naturezaOperacao = operacaoFiscal.naturezaOperacao;
    this.naoGerarComissao = operacaoFiscal.naoGerarComissao;
  }
}
