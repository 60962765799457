import { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextareaAutosize,
  CardHeader,
} from "@mui/material";

const InformacaoComplementarDialog = ({
  open,
  setOpen,
  venda,
  setVenda,
  pdvEntity,
}) => {
  const [informacaoComplementar, setInformacaoComplementar] = useState(null);

  const onChangeInput = (event) => {
    setInformacaoComplementar(event.target.value || null);
  };

  const informacaoComplementarSubmit = (event) => {
    event.preventDefault();
    pdvEntity.setHandleChangeInput(
      "informacoesAdicionais",
      informacaoComplementar
    );
    setVenda(pdvEntity);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setInformacaoComplementar(null);
    setOpen(false);
  };

  return (
    <Dialog maxWidth={"md"} open={open} onClose={handleCloseDialog} fullWidth>
      <form onSubmit={(event) => informacaoComplementarSubmit(event)}>
        <DialogContent>
          <CardHeader title="Informação Complementar" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextareaAutosize
                className="text-area w-100 p-2 mt-2"
                aria-label="informacoes-adicionais"
                name="informacaoComplementar"
                id="informacaoComplementar"
                onChange={onChangeInput}
                value={informacaoComplementar ?? venda?.informacoesAdicionais}
                minRows={5}
                placeholder="Informação Complementar"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className="centralizar mb-2">
            <Grid item xs={4}>
              <Button
                margin="normal"
                size="large"
                type="submit"
                variant="contained"
                disableElevation
                fullWidth
              >
                <i
                  className="ph-fill ph-check-circle"
                  style={{ color: "white", fontSize: 22, marginRight: 10 }}
                ></i>
                Salvar
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="inherit"
                margin="normal"
                size="large"
                variant="contained"
                onClick={handleCloseDialog}
                disableElevation
                fullWidth
              >
                <i
                  className="ph-fill ph-x-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InformacaoComplementarDialog;
